import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './App.css';

import { AppSpinnerFull } from './app/components/spinner/spinner-full';
import ProfileLoader from './app/modules/auth/profileLoader';
import { AppRoutes } from './app/routes/index';
import { selectSpinnerVisible } from './app/slices/commonSlice';

export const App = ({ history }) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const isSpinnerVisible = useSelector(selectSpinnerVisible);
  const element = document.querySelector('meta[build-version]');
  const currentVersion = element.getAttribute('build-version');

  const fetchRemoteVersion = useCallback(
    () =>
      fetch(`/signature.json?${Date.now()}`, {
        cache: 'no-store'
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          return data.version;
        }),
    []
  );

  const controlVersion = useCallback(() => {
    fetchRemoteVersion().then((version) => {
      if (version && currentVersion && version !== currentVersion) {
        setIsVisible(true);
      }
    });
  }, [currentVersion, fetchRemoteVersion]);

  useEffect(() => {
    const timer = setInterval(() => {
      controlVersion();
    }, 30000);
    return () => clearInterval(timer);
  }, [controlVersion, currentVersion, fetchRemoteVersion]);

  const hardReloadPage = () => {
    window.location.reload();
  };

  const handleOkClick = () => {
    hardReloadPage();
  };

  return (
    <div className={isVisible ? '' : 'App'}>
      <div className={`ant-modal-content mg-modal _info customModal`} style={{ display: isVisible ? 'block' : 'none' }}>
        <div className="ant-modal-header">
          <div className="ant-modal-title" style={{ textAlign: 'center' }}>
            {t('new_version')}
          </div>
        </div>
        <div className="ant-modal-body" style={{ textAlign: 'center' }}>
          <div>{t('reload_text')}</div>
        </div>
        <div className="ant-modal-footer">
          <button type="button" className="ant-btn ant-btn-primary mg-btn" onClick={handleOkClick}>
            <span>{t('reload_btn')}</span>
          </button>
        </div>
      </div>
      <ProfileLoader />
      {isSpinnerVisible ? <AppSpinnerFull></AppSpinnerFull> : ''}
      <AppRoutes history={history} />
    </div>
  );
};
